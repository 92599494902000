import React from 'react';
import { useCreateContext } from '../contexts/create-context';
import SEO from 'components/seo';

import { reducer } from '../contexts/app/app.reducer';
import { initialState } from '../contexts/app/app.reducer';

// Load other package css file
import 'react-multi-carousel/lib/styles.css';
import 'react-modal-video/css/modal-video.min.css';
import 'rc-drawer/assets/index.css';

import FeatureSection from '../feature-section/feature-section';
import Banner from '../banner-section/Banner';
import Faq from '../faq-section/faq';
import Layout from '../components/layout';

export default function IndexPage() {
  const [, , StickyProvider] = useCreateContext(initialState, reducer);

  return (
    <StickyProvider>
      <Layout>
        <SEO title="Gymbase | Social workout tracking" />
        <Banner context={'Home'} />
        <FeatureSection />
        <Faq />
      </Layout>
    </StickyProvider>
  );
}
